.loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.75);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  &.shown {
    opacity: 1;
    visibility: visible;
  }

  fa-icon {

  }
}

.below-scroll-bar {
  z-index: -1;
  position: relative;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Overall project page layout */

.page-header {
  height: 35vh;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $light;

  &.bg-image {
    //overflow: hidden;
    position: relative;
  }

  .page-header-inner {
    position: relative;
    text-align: center;
    z-index: 1;
    max-width: 600px;
  }

  .bg-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(#444, 0.60);
    //z-index: 1;
  }

  h1 {
    font-weight: bold;
    color: $light;
    margin-bottom: 2rem;
  }

  h2.subtitle {
    font-size: 1.2em;
    text-align: center;
    color: $light;
    font-weight: 200 !important;
  }

  //.blur {
  //  filter: blur(5px);
  //  transform: scale(1.2);
  //}
}

// TODO why not taken ? because content added via [innerHTML] ?
.extract-info {
  h3 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

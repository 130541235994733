/* You can add global styles to this file, and also import other style files */
@import 'styles/reset';
@import "~animate.css/animate.min.css";
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
//@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,800');
@import 'styles/variables';
@import '~bootstrap/scss/bootstrap';
@import "styles/custom-theme";
@import "styles/commons";
@import '~@fortawesome/fontawesome-svg-core/styles.css';

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  //overflow: auto;
  color: $dark;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

body {
  position: relative;
  //overflow: auto;
}

/*
 * We were having issues with z-index, now we know why !
 * https://github.com/daneden/animate.css/issues/596
 */
.animated {
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important
}

.card {
  background-color: $element-background-color;
  border-radius: 3px;
  box-shadow: $card-shadow;
  border: none;
}

.sticker {
  position: absolute;
  transform: rotate(15deg);
  border-radius: 7px;
  height: 14px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 14px;
  top: 0;
  right: 0;

  &.sticker-info {
    background-color: $info;
    color: $light
  }

  &.sticker-danger {
    background-color: $danger;
    color: $light
  }

  &.sticker-warning {
    background-color: $warning;
    color: $light
  }

  &.sticker-success {
    background-color: $success;
    color: $light
  }

  &.sticker-primary {
    background-color: $primary;
    color: $light
  }

  &.sticker-secondary {
    background-color: $secondary;
    color: $light
  }
}

label {
  display: block;
  font-weight: 600;

  &:not(:first-of-type) {
    margin-top: $margin / 2;
  }
}

.form-control-invisible {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.background-layer {
  //filter: grayscale(50%);
}

.breathe {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.icon-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $light;

  $w: 40px;
  width: $w;
  height: $w;
  border-radius: 50%;

  transition: background .1s linear, color .1s linear, border-color .1s linear;

  &:hover {
    border-color: transparent;
    background: $gray-600;
    color: $light;
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.ol-attribution {
  position: absolute !important;
  bottom: 0;
  right: 0;
  border: 2px;
  background-color: $background-color;

  button {
    display: none;
  }

  ul {
    margin: 0;
    padding: 1px 0.5em;
    font-weight: 1.5;
    color: #000;
    font-size: 11px;
  }

  li {
    display: inline;
    list-style: none;
  }
}
/*
 * https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html#custom-theme-using-built-in-color-palettes
 */
@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "variables";

//@include mat-core();
//
//$custom-theme-primary: mat-palette($mat-gray);
//$custom-theme-accent: mat-palette($mat-orange, A200, A100, A400);
//$custom-theme-warn: mat-palette($mat-red);
//$custom-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
//
//@include angular-material-theme($custom-theme);

.mat-form-field {
  //margin-bottom: -1.25em !important;
  //margin-left: 1em;
  //margin-right: 1em;
}

.mat-drawer-container {
  background-color: #ffffff;
}

.mat-chip.mat-standard-chip {
  background-color: $bipt-gray;
}

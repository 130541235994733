@import "~normalize.css/normalize.css";

/***********************/
/* Custom */
/***********************/

/*
 * Remove outline
 * https://stackoverflow.com/questions/3397113/how-to-remove-border-outline-around-text-input-boxes-chrome
 */

textarea:focus, input:focus {
  outline: none;
}

*:focus {
  outline: none !important;
}

input[type=text] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

button:focus, button:active {
  outline: 0 !important;
}

/*
 * Remove bootstrap button style
 */

a {
  cursor: pointer;
}

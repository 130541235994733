@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$bipt-dark-blue: #2e2f7f;
$bipt-light-blue: #67a7de;
$bipt-white: #ffffff;
$bipt-dark: #1e1e1c;
$bipt-gray: rgb(233, 233, 233);
$bipt-light-bg: #eaf6fc;
$bipt-border-bottom: #316cb0;

$bipt-red: rgb(227, 43, 41);
$bipt-orange: rgb(233, 90, 0);
$bipt-yellow: rgb(251, 177, 0);
$bipt-green: #559c4e;
$bipt-cyan: #53aaa7;
$bipt-purple: #85ad94;

$proximus-brand: rgb(92, 45, 145);
$orange-brand: rgb(255, 121, 0);
$telenet-brand: rgb(255, 196, 33);

// Menu
$corporate-menu-bg: #f2f3f6;
$menu-width: 240px;
$menu-item-height: 50px;
$footer-height: 40px;

//$body-bg: #FFFFFF;
$font-size-base: 1rem;

//$body-bg: #F2F2F2;
//$body-bg: #FAFAFA;
$body-bg: $bipt-white;
$body-color: $bipt-dark;
$headings-color: $bipt-dark-blue;
$font-family-sans-serif: "Nunito Sans", "Open Sans", sans-serif;
$font-family-base: "Nunito Sans", "Open Sans", sans-serif;
$headings-font-family: "Nunito Sans", "Open Sans", sans-serif;
.mat-select,
.mat-input-element,
.mat-form-field-label {
  font-family: "Nunito Sans", "Open Sans", sans-serif !important;
}

$font-weight-base: 300;
$headings-font-weight: 300;

$h2-font-size: 2em;

$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-width: 0; // Because .is-{valid/invalid} has a box shadow applied as well
$btn-focus-width: 0;
$input-btn-focus-box-shadow: none;

// TODO how to disable box shadow on .is-valid / .is-invalid

$input-border-radius: 0;
$btn-border-radius: 0;

$theme-colors: (
  "primary": $bipt-dark-blue,
  "success": $bipt-green,
  "danger": $bipt-red,
);

$valid-color: map-get($theme-colors, "success");
$invalid-color: map-get($theme-colors, "danger");

$dropdown-link-disabled-color: $gray-200;

$placeholder-color: $gray-400;

// Custom variables

$golden-ratio: 1.618; // Golden ratio

$nav-superposition: 42px;
$margin: 16px;

$primary: $bipt-dark-blue;
$secondary: $bipt-light-blue;
$info: $bipt-light-blue;
$light: $bipt-white;
$not-quite-light: #f5f5f5;
$dark: $bipt-dark;

$link-color: $info;
$background-color: $not-quite-light;
$element-background-color: $light;

$grid-gutter-width: $margin * 2;

$card-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05),
  0 6px 20px 0 rgba(0, 0, 0, 0.02);

$mobile-breakpoint: xs;
$tablet-breakpoint: md;
$home-card-bg: #6d7275;

$navbar-height: 50px;

$navbar-item-active-bg-color: darken($light, 5%);
$navbar-link-hover-bg-color: darken($light, 10%);
